<template>
  <v-btn color="primary" type="submit" class="mr-4"> {{ text }} </v-btn>
</template>

<script>
export default {
  props: {
    text: { default: 'Сохранить' },
  },
}
</script>
