<template>
  <div>
    <FormSaveButton class="mr-4" />
    <BackButton />
  </div>
</template>

<script>
import FormSaveButton from '@/components/buttons/FormSaveButton.vue'
import BackButton from '@/components/buttons/BackButton.vue'

export default {
  components: {
    FormSaveButton,
    BackButton,
  },
}
</script>
