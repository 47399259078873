/*
  Миксин:
    1. Устанавливает значения формы
      - получает начальные значения
      - если в компоненте объявлен метод prepareDefaultValues, то вызывает его до установки значения формы
      (Это бывает нужно для корректной работы валидации или если на момент create еще нет всех дефолтных данных)
      - устанавливает значения формы в подготовленные дефолтные или начальные
    2. При отправке формы:
      - Вызывает валидацию
      - Вызывает метод add или edit компонента в зависимости от наличия начальных значений 
*/

export const formMixin = {
  props: ['initialValues'],
  watch: {
    initialValues() {
      this.setInitialValues()
    },
  },
  created() {
    this.setInitialValues()
  },
  methods: {
    setInitialValues() {
      if (typeof this.prepareDefaultValues === 'function') {
        this.prepareDefaultValues()
      }
      this.formData = this.initialValues
        ? { ...this.TEMPLATE_FORM_DATA, ...this.initialValues }
        : { ...this.TEMPLATE_FORM_DATA }
    },
    onSubmit() {
      if (!this.$refs.form.validate()) return
      if (this.initialValues) {
        this.edit()
      } else {
        this.add()
      }
    },
  },
}
