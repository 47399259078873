<template>
  <v-menu
    v-model="deadlineMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="deadline"
        label="Крайний срок"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="deadline"
      @input="onDateInput"
      locale="ru-ru"
      :first-day-of-week="1"
      :min="minDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/**
 * v-date-picker accepts ISO 8601 date strings (date only!) (YYYY-MM-DD)
 */

export default {
  model: {
    prop: 'deadline',
  },
  data() {
    return {
      deadlineMenu: false,
    }
  },
  props: {
    deadline: {
      type: String,
      required: true,
    },
    minDate: {
      type: String,
      required: true,
    },
  },
  methods: {
    onDateInput(value) {
      this.deadlineMenu = false
      this.$emit('input', value)
    },
  },
}
</script>
