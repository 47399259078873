<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Страница редактирования проекта" />
      <ProjectForm :initialValues="project" />
    </div>
    <div v-else>Нет проекта</div>
  </DisplayLoading>
</template>

<script>
import DisplayLoading from '@/components/common/DisplayLoading.vue'
import ProjectForm from '@/components/forms/ProjectForm.vue'
import PageHeader from '@/components/common/PageHeader.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_PROJECTS } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю проект',
    }
  },
  components: {
    ProjectForm,
    PageHeader,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_PROJECTS}/${this.projectId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для заболевания (состояния): ${this.projectId}`
    },
    project() {
      return this.pageData
    },
  },
}
</script>
