<template>
  <v-form @submit.prevent="onSubmit" ref="form">
    <v-text-field
      v-model="formData.name"
      label="Название проекта"
      :rules="[rules.required]"
      validate-on-blur
    ></v-text-field>
    <v-text-field
      v-model="formData.description"
      label="Описание"
      validate-on-blur
    ></v-text-field>
    <SelectData v-model="formData.time_deadline" :minDate="minDate" />
    <FormButtons />
  </v-form>
</template>

<script>
import FormButtons from '@/components/buttons/FormButtons.vue'
import SelectData from '@/components/forms/SelectDate.vue'

import { routeParams } from '@/mixins/routeParams'
import { formMixin } from '@/mixins/formMixin'
import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'

import { formatDate, addDays } from '@/lib/datetime'
import { addProject, editProject } from '@/lib/requestsAPI'

import { ADMIN, PROJECTS, PROJECT_SHORT } from '@/store/const/path'

export default {
  mixins: [formMixin, routeParams, sendFormWithToastAndRedirect],
  components: {
    FormButtons,
    SelectData,
  },
  data() {
    return {
      formData: {},
      TEMPLATE_FORM_DATA: {
        name: '',
        description: '',
        time_deadline: formatDate(addDays(new Date(), 14)),
      },
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
    }
  },
  computed: {
    minDate() {
      return this.initialValues
        ? formatDate(this.initialValues.time_create)
        : formatDate(new Date())
    },
    successPath() {
      return `/${ADMIN}/${PROJECTS}/${PROJECT_SHORT}-`
    },
  },
  methods: {
    prepareDefaultValues() {
      if (this.initialValues) {
        this.initialValues.time_deadline = formatDate(
          this.initialValues.time_deadline
        )
      }
    },
    prepareSendData() {
      if (this.formData.description === '') this.formData.description = null
      return {
        ...this.formData,
        time_deadline: new Date(this.formData.time_deadline),
      }
    },
    add() {
      this.sendFormWithToastAndRedirect({
        action: addProject,
        data: this.prepareSendData(),
        successPath: this.successPath,
        updateSuccessPath: true,
        successMessage: `Проект ${this.formData.name} добавлен`,
        errorMessage: 'Что-то пошло не так с добавлением проекта',
      })
    },
    edit() {
      this.sendFormWithToastAndRedirect({
        action: editProject,
        data: {
          id: this.projectId,
          data: this.prepareSendData(),
        },
        successPath: `${this.successPath}${this.projectId}`,
        updateSuccessPath: false,
        successMessage: `Проект ${this.formData.name} отредактирован`,
        errorMessage: `Что-то пошло не так с редактированием проекта ${this.projectId}`,
      })
    },
  },
}
</script>
