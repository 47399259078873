<template>
  <v-btn @click="onBack">{{ label }}</v-btn>
</template>

<script>
export default {
  props: {
    label: {
      default: 'Отменить',
    },
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
  },
}
</script>
